import axios from '@/plugins/axios.js'

// 查询配置信息
export const queryGlobalConfigure = () => axios.post('/globe/query')

//保存配置信息
export const saveGlobalConfigure = (params) => axios.post('/globe/save', params)


// 保存且更新配置信息
export const saveAndUpdateGlobalConfigure = (params) => axios.post('/globe/saveAndUpdate', params)







