<template>
  <div class="page">
    <div class="page-main">
      <div class="global">
        <el-form
          label-width="233px"
          label-position="right"
          :model="settingsForm"
          :rules="modifyIPRules"
          ref="form"
        >
          <el-form-item :label="$t('global.apUrl')">
            <el-input v-model="settingsForm.autoProvisioningUrl" :placeholder="$t('global.apUrlPlaceholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('global.deviceLanguage')">
            <el-select
              v-model="settingsForm.deviceLanguage"
              :placeholder="$t('global.deviceLanguagePlaceholder')"
            >
              <el-option
                v-for="(value, key) in languageOptions"
                :key="key"
                :label="value"
                :value="value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('global.nsia')" prop="ntpServerIp">
            <el-input v-model="settingsForm.ntpServerIp" :placeholder="$t('global.nsiaPlaceholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('global.apUsername')">
            <el-input v-model="settingsForm.autoProvisioningUsername" :placeholder="$t('global.apUsernamePlaceholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('global.apPassword')">
            <el-input
              type="password"
              v-model="settingsForm.autoProvisioningPassword"
              :placeholder="$t('global.apPasswordPlaceholder')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('global.repeatedlyAutoP')">
            <el-switch v-model="settingsForm.repeatedly"></el-switch>
          </el-form-item>
          <el-form-item :label="$t('global.intervalM')" prop="interval">
            <el-input v-model="settingsForm.interval" style="width: 220px"></el-input>
            <!-- <span style="margin-left: 10px">{{$t('global.minutes')}}</span> -->
          </el-form-item>
          <el-form-item class="footer-btns">
            <el-button
              class="footer-btn"
              type="primary"
              plain
              @click="save"
            >{{$t('save')}}</el-button>
            <el-button
              class="footer-btn"
              type="primary"
              @click="saveAndUpdate"
            >{{$t('global.saveUp')}}</el-button>
            <el-button
              class="footer-btn"
              type="primary"
              @click="wipe"
            >{{$t('global.wipe')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  queryGlobalConfigure,
  saveGlobalConfigure,
  saveAndUpdateGlobalConfigure,
} from '@/api/global'
export default {
  name: 'Global',
  data(){
    // var validateIP = (rule, value, callback) => {
    //   var reg = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/
    //   if (reg.test(value) || value == '') {
    //     callback()
    //   } else {
    //     callback(new Error(this.$t('global.petcia')))
    //   }
    // }
    // var validateInterval = (rule,value, callback) => {
    //   if( value < 1 || value>7200){
    //     callback(new Error(this.$t('global.intervalError')))
       
    //   }else{
    //     callback()
    //   }
    // }
    return{
      settingsForm:{
        autoProvisioningUrl:'',
        deviceLanguage:'',
        ntpServerIp:'',
        autoProvisioningUsername:'',
        autoProvisioningPassword:'',
        repeatedly:false,
        interval:'86400',
        // id:''
      },
      modifyIPRules: {
        // ntpServerIp: {
        //   validator: validateIP,
        //   trigger: 'blur',
        // },
        // interval:{
        //   validator: validateInterval,
        //   trigger: 'blur',
        // }
      },
    }
  },
  computed:{
    ...mapGetters('dist', ['languageOptions']),
  },
  mounted() {
    queryGlobalConfigure().then((res) => {
      if (res.data.code == 0) {
        this.settingsForm = {
          ...res.data.result.rows[0],
          interval: res.data.result.rows[0].interval ? res.data.result.rows[0].interval : 86400
        }
      }
    })
  },
  methods: {
    // 保存
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          saveGlobalConfigure(this.settingsForm)
            .then((res) => {
              this.settingsForm.id = res.data.result.rows[0]
              this.$message({
                message: res.data.message,
                type: 'success',
              })
            })
            .catch(() => {
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 保存更新
    saveAndUpdate() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          saveAndUpdateGlobalConfigure(this.settingsForm)
            .then((res) => {
              this.$message({
                message: res.data.message,
                type: 'success',
              })
            })
            .catch(() => {
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 清空
    wipe() {
      let settingsForm = {
        autoProvisioningUrl: '',
        deviceLanguage: '',
        ntpServerIp: '',
        autoProvisioningUsername: '',
        autoProvisioningPassword: '',
        repeatedly: false,
        interval: 86400,
      }
      this.settingsForm = {
        ...this.settingsForm,
        ...settingsForm,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 84px 140px 18px;
}
.global {
  .el-select {
    width: 100%;
  }
  .footer-btns {
    margin-top: 150px;
  }
}
</style>
